module services {
    export module customs {
        export class flowTypeService implements interfaces.customs.IFlowTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            getForDropdown(declarationCountryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "FlowType/GetForDropdown", {
                    declarationCountryId: declarationCountryId,
                });
            }
        }
    }
    angular.module("app").service("flowTypeService", services.customs.flowTypeService);
}